<template>
  <div>
    <NewsCategory :site="3" />
  </div>
</template>

<script>
import NewsCategory from "@/views/official-website/category/index.vue";
export default {
  components: {
    NewsCategory,
  },
};
</script>

<style lang="less" scoped></style>
